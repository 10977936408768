import * as React from 'react';
import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

// Components
import Layout from '../../components/layout/Layout';
import ServiceCard from '../../components/card/Service';
//import NewAddressSection from '../../components/section/NewAddress';
import CountsSection from '../../components/section/Counts';

// Services
const ServicesPage = ({ pageContext, data }) => {
  const { totalCount } = data.services
  const pageTitle = `Services - ${totalCount} service${totalCount === 1 ? "" : "s"}`

  const headerBackgroundImageRandom = Math.floor(Math.random() * data.services.nodes.length);
  const headerBackgroundImage = data.services.nodes[headerBackgroundImageRandom].frontmatter.image.cover ? data.services.nodes[headerBackgroundImageRandom].frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`${pageTitle} | ${data.site.siteMetadata.title} - L'entreprise spécialiste du bâtiment à Roanne`}
      pageDescription={`L'équipe compétente et qualifiée de ${data.site.siteMetadata.title} saura vous apporter toutes les informations et le savoir-faire pour la bonne réalisation de votre projet personnel ou professionnel.`}
      pageKeywords="pcc bâtiment, service, roanne, riorges, loire, rénovation, construction, plâtrerie peinture, isolation thermique, faience, façade, sols souples"
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider layer-overlay overlay-dark-8 section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title text-white">Nos services</h2>
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">Nos services</span>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {data.services && data.services.nodes.length ?
        <section id="services">
          <div className="container">
            <div className="section-title">
              <div className="row mb-40">
                <div className="col-sm-6">
                  <h5 className="tm-sc tm-sc-line-with-text mt-0 mb-0 text-gray line-after-title "> <span className="horizontal-text">Nos compétences</span> <span className="horizontal-line bg-theme-colored1"></span></h5>
                  <div className="tm-sc section-title section-title-style1 text-left">
                    <div className="title-wrapper">
                      <h2 className="title"> <span>Ce que nous </span> <span className="text-theme-colored1">faisons</span></h2>
                      <div className="title-seperator-line"></div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mt-sm-15 mt-10 mb-0">
                    <p>Notre équipe compétente et qualifiée saura vous apporter toutes les informations et le savoir-faire pour la bonne réalisation de votre projet personnel ou professionnel.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                {data.services.nodes.map(service => (
                  <ServiceCard key={service.id} data={service} cardStyle="1" cardClassName="col-md-6 col-lg-6 col-xl-4" />
                ))}
              </div>
            </div>
          </div>
        </section>
      : null}
      {/*<NewAddressSection site={data.site} sectionStyle="1" />*/}
      <CountsSection site={data.site} sectionStyle="3"/>
    </Layout>
  )
}

export const query = graphql`
  query {  
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    services: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/services/" }, 
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___order, order: ASC}
    ) {
      nodes {
        frontmatter {
          title
          description
          image {
            icon {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            cover {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
      totalCount
    }
  }
`

export default ServicesPage